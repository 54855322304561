import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ArrowDown as ArrowDownIcon, ChartLineUp } from "@phosphor-icons/react";
import { ArrowUp as ArrowUpIcon } from "@phosphor-icons/react";

export function TopItem({ title, diff, trend, sx, stats }) {
  const TrendIcon = trend === "up" ? ArrowUpIcon : ArrowDownIcon;
  const trendColor = trend === "up" ? "green" : "orange";
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (stats) {
      const data = stats.find((d) => d.Type === title);
      console.log(stats);

      if (data) {
        setValue(data.sum);
      }
    }
  }, [stats, title]);

  return (
    <Card
      style={{
        borderRadius: "16px",
        boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
      }}
    >
      <CardContent>
        <Stack spacing={3}>
          <Stack
            direction="row"
            sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography color="text.secondary" variant="overline">
                {title}
              </Typography>
              <Typography variant="h4">{value}</Typography>
            </Stack>
            <Avatar
              sx={{
                backgroundColor: "orange",
                height: "32px",
                width: "32px",
              }}
            >
              <ChartLineUp fontSize="16px" />
            </Avatar>
          </Stack>
          {diff ? (
            <Stack sx={{ alignItems: "center" }} direction="row" spacing={2}>
              <Stack
                sx={{ alignItems: "center" }}
                direction="row"
                spacing={0.5}
              >
                <TrendIcon
                  color={trendColor}
                  fontSize="var(--icon-fontSize-md)"
                />
                <Typography color={trendColor} variant="body2">
                  {diff}%
                </Typography>
              </Stack>
              <Typography color="text.secondary" variant="caption">
                Since last month
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
}
