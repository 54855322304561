import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { PieChart } from "@mui/x-charts/PieChart";

export default function Pie({ stats, title }) {
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    if (stats) {
      const opt1 = stats.find((d) => d.Type === "Support Issues Reported");
      const opt2 = stats.find((d) => d.Type === "Support Issues Resolved");
      setChartSeries([
        { value: opt1 ? opt1.sum : 0, label: "Issues Reported" },
        { value: opt2 ? opt2.sum : 0, label: "Issues Resolved" },
      ]);
    }
  }, [stats, title]);

  return (
    <Card
      sx={{
        borderRadius: "16px",
        boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
      }}
    >
      <CardHeader title={title} />
      <CardContent>
        <PieChart
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: 'bottom', horizontal: 'middle' },
              padding: 0
            }
          }}
          series={[
            {
              data: chartSeries,
              innerRadius: 50,
              outerRadius: 100,
              paddingAngle: 2,
              cx: 150,
              cy: 150,
            },
          ]}
          width={300}
          height={350}
        />
      </CardContent>
    </Card>
  );
};