import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CreateUser from "../components/users/CreateUser";

export default function Users(props) {
  const [data, setData] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [changed, setChanged] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState(null);

  React.useEffect(() => {
    setData(null);
    fetch(`/api/auth/paginated/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {});
  }, [changed, currentPage]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setChanged(!changed); // Refresh data after user creation
  };

  const handleMenuOpen = (event, user) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedUser(null);
  };

  const handleDelete = () => {
    // Implement delete functionality here
    console.log(`Deleting user ${selectedUser.UserID}`);
    handleMenuClose();
  };

  const handleUpdate = () => {
    // Implement update functionality here
    console.log(`Updating user ${selectedUser.UserID}`);
    handleMenuClose();
  };

  const handleDeactivate = () => {
    // Implement deactivate functionality here
    console.log(`Deactivating user ${selectedUser.UserID}`);
    handleMenuClose();
  };

  return (
    <Box marginTop={8} padding={1}>
      <Stack spacing={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Users</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogOpen}
          >
            Create User
          </Button>
        </Box>
        <Card
          style={{
            borderRadius: "16px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box sx={{ overflowX: "auto" }}>
            <Table sx={{ minWidth: "800px" }}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox onChange={(event) => {}} />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.users &&
                  data.users.map((user) => (
                    <TableRow key={user.UserID}>
                      <TableCell padding="checkbox">
                        <Checkbox />
                      </TableCell>
                      <TableCell>{user.Name}</TableCell>
                      <TableCell>{user.Email}</TableCell>
                      <TableCell>{user.Department}</TableCell>
                      <TableCell>{user.Position}</TableCell>
                      <TableCell>
                        {user.Status ? "Active" : "Inactive"}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(event) => handleMenuOpen(event, user)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchorEl}
                          open={Boolean(menuAnchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={handleUpdate}>Update</MenuItem>
                          <MenuItem onClick={handleDeactivate}>
                            Deactivate
                          </MenuItem>
                          <MenuItem onClick={handleDelete}>Delete</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
          <Divider />
          <TablePagination
            component="div"
            count={data != null ? data.total : 0}
            page={currentPage - 1}
            onPageChange={handlePageChange}
            rowsPerPage={12}
            rowsPerPageOptions={[12]}
          />
        </Card>
      </Stack>
      <CreateUser open={dialogOpen} onClose={handleDialogClose} />
    </Box>
  );
}
