import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { Alert, Box } from "@mui/material";

export default function Settings() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    // Prepare data for the API request
    const requestData = {
      Password: oldPassword,
      NewPassword: newPassword,
    };

    if (requestData.Password.length <= 5 || requestData.Password.length <= 5)
      return setError("Enter a valid password");
    try {
      // Send the PUT request to update the password
      fetch(`/api/auth/resetpassword`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error("Failed to update password");
        })
        .then((data) => {
          if (data.success != null) {
            setError(data.success);
          } else setError(data.error);
        })
        .catch((e) => {
          setError("Failed to update password");
        });
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box marginTop={8} padding={1}>
      <Stack spacing={3}>
        <form onSubmit={handleSubmit}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <CardHeader subheader="Update password" title="Password" />
            <Divider />
            <CardContent>
              <Stack spacing={3} sx={{ maxWidth: "sm" }}>
                <FormControl fullWidth>
                  <InputLabel>Old Password</InputLabel>
                  <OutlinedInput
                    label="Old Password"
                    name="oldPassword"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>New Password</InputLabel>
                  <OutlinedInput
                    label="New Password"
                    name="newPassword"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Confirm Password</InputLabel>
                  <OutlinedInput
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FormControl>
                {error && <Alert color="warning">{error}</Alert>}
              </Stack>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit">
                Update
              </Button>
            </CardActions>
          </Card>
        </form>
      </Stack>
    </Box>
  );
}
