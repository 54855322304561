import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  Divider,
  Alert,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";

export default function CreateActivityDialog({ open, onClose, activity }) {
  const [form, setForm] = useState({
    UserID: "",
    Date: dayjs().format("YYYY-MM-DD"),
    Value: 0,
    Type: "",
    Remarks: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Use useEffect to populate the form with the activity data if editing
  useEffect(() => {
    if (activity) {
      setForm({
        UserID: activity.UserID || "",
        Date: activity.Date
          ? dayjs(activity.Date).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"),
        Value: activity.Value || 0,
        Type: activity.Type || "",
        Remarks: activity.Remarks || "",
      });
    } else {
      // Reset form if not editing
      setForm({
        UserID: "",
        Date: dayjs().format("YYYY-MM-DD"),
        Value: 0,
        Type: "",
        Remarks: "",
      });
    }
  }, [activity]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setError(""); // Reset error
    // Check if all required fields are filled
    if (!form.Date || !form.Value || !form.Type) {
      setError("Please fill out all required fields.");
      return;
    }

    setLoading(true); // Start loading
    setError(""); // Reset error

    const url = activity
      ? `/api/activities/${activity.ID}` // Update activity if editing
      : "/api/activities/create"; // Create a new activity if not editing

    const method = activity ? "PUT" : "POST"; // Determine the method

    fetch(url, {
      method: method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) {
          window.location.href = "/";
        } else throw new Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data?.success) {
          setError(data.success);
          setForm({
            UserID: "",
            Date: dayjs().format("YYYY-MM-DD"),
            Value: 0,
            Type: "",
            Remarks: "",
          });
          setTimeout(() => {
            setError("");
            onClose();
          }, 500);
        } else {
          setError(data.error);
          setTimeout(() => {
            setError("");
          }, 1000);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError("Error submitting activity. Please try again.");
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {activity ? "Update Activity" : "Create New Activity"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          label="Type *"
          name="Type"
          value={form.Type}
          onChange={handleChange}
          select
          fullWidth
          margin="normal"
        >
          <MenuItem value="Quotes">Quotes</MenuItem>
          <MenuItem value="Client Visits">Client Visits</MenuItem>
          <MenuItem value="Client Calls">Client Calls</MenuItem>
          <MenuItem value="New Prospects">New Prospects</MenuItem>
          <MenuItem value="Sales Closed">Sales Closed</MenuItem>
          <MenuItem value="Support Issues Reported">
            Support Issues Reported
          </MenuItem>
          <MenuItem value="Support Issues Resolved">
            Support Issues Resolved
          </MenuItem>
        </TextField>
        <TextField
          label="Date *"
          name="Date"
          type="date"
          value={form.Date}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Value *"
          name="Value"
          type="number"
          value={form.Value}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Remarks"
          name="Remarks"
          value={form.Remarks}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        {error && (
          <Alert
            severity={error.includes("successfully") ? "success" : "warning"}
          >
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          sx={{ px: 5 }}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={loading} // Disable button while loading
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : activity ? (
            "Update"
          ) : (
            "Create"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
