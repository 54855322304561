import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "./Navbar";
import Home from "../pages/Home";
import Users from "../pages/Users";
import Settings from "../pages/Settings";
import Activities from "../pages/Activities";
import Performance from "../pages/Performance";

function Dashboard() {
  const [user, setUser] = React.useState(null);
  return (
    <Box sx={{ display: "flex" }}>
      <Navbar user={user} setUser={setUser} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="activities" element={<Activities />} />
          {user && user.Role == "Admin" && (
            <Route path="users" element={<Users />} />
          )}
          <Route path="performance" element={<Performance />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default Dashboard;
