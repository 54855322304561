import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Chip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function Performance() {
  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(Date.now());
  const [endDate, setEndDate] = React.useState(Date.now());

  React.useEffect(() => {
    if (startDate && endDate) {
      const start = dayjs(startDate).toISOString().split("T")[0];
      const end = dayjs(endDate).toISOString().split("T")[0];
      fetch(`/api/activities/performance/${start}/${end}`)
        .then((response) => response.json())
        .then((data) => {
          setData(formatData(data));
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [startDate, endDate]);

  const formatData = (data) => {
    const formattedData = [];

    // Extract names from the data
    const names = data.map((item) => item.Name);

    names.forEach((name) => {
      const entry = {
        Name: name,
        AE: name,
        ClientVisits: 0,
        QuotesDone: 0,
        NewProspect: 0,
        SupportIssuesReported: 0,
        SupportIssuesSolved: 0,
        SalesClosed: 0,
        ClientCalls: 0,
        CompletionRate: 0,
      };

      const personData = data.find((d) => d.Name === name);
      if (personData) {
        let rate = 0;
        personData.Values.forEach(({ Type, sum }) => {
          let v = 0;
          switch (Type) {
            case "Client Visits":
              if (sum > 0) v = 10;
              entry.ClientVisits = sum;
              break;
            case "Quotes":
              if (sum == 1) v = 10;
              else if (sum > 1) v = 20;
              entry.QuotesDone = sum;
              break;
            case "New Prospects":
              if (sum > 0) v = 10;
              entry.NewProspect = sum;
              break;
            case "Support Issues Reported":
              if (sum > 0) v = 5;
              entry.SupportIssuesReported = sum;
              break;
            case "Support Issues Resolved":
              if (sum > 0) v = 10;
              entry.SupportIssuesSolved = sum;
              break;
            case "Client Calls":
              v = sum * 5 > 5 ? 25 : sum * 5;
              entry.ClientCalls = sum;
              break;
            case "Sales Closed":
              if (sum > 0) v = 20;
              entry.SalesClosed = sum;
              break;
            default:
              break;
          }
          rate += v;
          console.log(Type, v);
        });
        entry.CompletionRate = rate;
      }

      formattedData.push(entry);
    });

    return formattedData;
  };

  return (
    <Box marginTop={8} padding={1}>
      <Stack spacing={3}>
        <Box sx={{ display: "flex", gap: 3 }}>
          <Typography variant="h6">Staff Performance</Typography>
          <Box sx={{ flexGrow: 1 }}></Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={dayjs(startDate)}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" margin="normal" />
              )}
            />
            <DatePicker
              label="End Date"
              value={dayjs(endDate)}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" margin="normal" />
              )}
            />
          </LocalizationProvider>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Client Visits</TableCell>
                <TableCell>Quotes Done</TableCell>
                <TableCell>New Prospect</TableCell>
                <TableCell>Support Issues Reported</TableCell>
                <TableCell>Support Issues Solved</TableCell>
                <TableCell>Sales Closed</TableCell>
                <TableCell>Client Calls</TableCell>
                <TableCell>Completion Rate (%)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.Name}>
                  <TableCell width={200}>{row.AE}</TableCell>
                  <TableCell>{row.ClientVisits}</TableCell>
                  <TableCell>{row.QuotesDone}</TableCell>
                  <TableCell>{row.NewProspect}</TableCell>
                  <TableCell>{row.SupportIssuesReported}</TableCell>
                  <TableCell>{row.SupportIssuesSolved}</TableCell>
                  <TableCell>{row.SalesClosed}</TableCell>
                  <TableCell>{row.ClientCalls}</TableCell>
                  <TableCell>
                    <Chip
                      color={
                        row.CompletionRate >= 70
                          ? "success"
                          : row.CompletionRate >= 50
                          ? "warning"
                          : "error"
                      }
                      sx={{ px: 2 }}
                      label={row.CompletionRate + "%"}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
}
