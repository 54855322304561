import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Chip,
} from "@mui/material";
import dayjs from "dayjs";
import CreateActivityDialog from "../components/activities/CreateActivityDialog";
import ConfirmDialog from "../components/ConfirmDialog"; // Import the ConfirmDialog component
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { debounce } from "lodash"; // Import lodash debounce

export default function Activities(props) {
  const [activities, setActivities] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [activityToDelete, setActivityToDelete] = useState(null);
  const [searchText, setSearchText] = useState(""); // Add state for search text

  useEffect(() => {
    fetchActivities();
  }, [page, change, rowsPerPage, searchText]);

  const fetchActivities = () => {
    setLoading(true);
    const url = searchText
      ? `/api/activities/wildsearch/${searchText}`
      : `/api/activities/myactivities/${rowsPerPage}/${page * rowsPerPage}`;
    fetch(`${url}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) {
          window.location.href = "/";
        } else throw new Error("");
      })
      .then((data) => {
        setLoading(false);
        setActivities(data?.data);
        setCount(data?.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (activity) => {
    setCurrentActivity(activity);
    setDialogOpen(true);
  };

  const handleDelete = async () => {
    if (activityToDelete) {
      try {
        fetch(`/api/activities/${activityToDelete.ID}`, {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.ok) return res.json();
            else if (res.status === 401) {
              window.location.href = "/";
            } else throw new Error("");
          })
          .then((data) => {
            setActivities(
              activities.filter(
                (activity) => activity.ID !== activityToDelete.ID
              )
            );
            setActivityToDelete(null);
          })
          .catch((e) => {});
      } catch (error) {
        console.error("Error deleting activity:", error);
      } finally {
      }
    }
  };

  const openConfirmDialog = (activity) => {
    setActivityToDelete(activity);
    setConfirmDialogOpen(true);
  };

  const handleSearchChange = debounce((event) => {
    setSearchText(event.target.value);
    setPage(0); // Reset to the first page on new search
  }, 500);

  return (
    <Box marginTop={8} padding={1}>
      <Stack spacing={3}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ flexGrow: 1 }} variant="h6">
            My Activities
          </Typography>
          <TextField
            label="Search Type|Date|Remark..."
            variant="outlined"
            size="small"
            onChange={handleSearchChange}
            sx={{ marginRight: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCurrentActivity(null); // Clear current activity for create dialog
              setDialogOpen(true);
            }}
          >
            Add Activity
          </Button>
        </Box>
        <Card
          style={{
            borderRadius: "16px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box sx={{ overflowX: "auto", minHeight: "50vh" }}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table sx={{ minWidth: "800px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Remarks</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell padding="checkbox">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activities.map((activity) => (
                    <TableRow key={activity.ID}>
                      <TableCell>
                        {dayjs(activity.Date).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>{activity.Type}</TableCell>
                      <TableCell>{activity.Remarks}</TableCell>
                      <TableCell>
                        {" "}
                        <Chip label={activity.Value} />
                      </TableCell>
                      <TableCell sx={{ display: "flex" }}>
                        <IconButton
                          color="primary"
                          onClick={() => handleEdit(activity)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => openConfirmDialog(activity)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
          <Divider />
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      </Stack>
      <CreateActivityDialog
        open={dialogOpen}
        onClose={() => {
          setChange(!change);
          setDialogOpen(false);
        }}
        activity={currentActivity}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleDelete}
        title="Delete Activity"
        content="Are you sure you want to delete this activity?"
      />
    </Box>
  );
}
