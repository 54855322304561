import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

const chartSetting = {
  yAxis: [
    {
      label: "No.",
    },
  ],
  width: 700,
  height: 350,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-20px, 0)",
    },
  },
};

const valueFormatter = (value) => `${value}`;

export default function BarsDataset() {
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState("All");

  useEffect(() => {
    fetch("/api/activities/barchart")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleFilterChange = (type) => {
    setSelectedType(type);
  };

  const filteredData = data.map((item) => {
    if (selectedType === "All") {
      const parsedItem = { month: item.month };
      Object.keys(item).forEach((key) => {
        if (key !== 'month') {
          parsedItem[key] = parseInt(item[key]);
        }
      });
      return parsedItem;
    } else {
      return {
        month: item.month,
        [selectedType]: parseInt(item[selectedType]),
      };
    }
  });
  

  return (
    <Card
      sx={{
        borderRadius: "16px",
        boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
      }}
    >
      <CardHeader
        action={
          <ButtonGroup variant="outlined">
            <Button
              variant={selectedType === "All" ? "contained" : "outlined"}
              onClick={() => handleFilterChange("All")}
            >
              All
            </Button>
            <Button
              variant={
                selectedType === "Client Visits" ? "contained" : "outlined"
              }
              onClick={() => handleFilterChange("Client Visits")}
            >
              Client Visits
            </Button>
            <Button
              variant={
                selectedType === "New Prospects" ? "contained" : "outlined"
              }
              onClick={() => handleFilterChange("New Prospects")}
            >
              New Prospects
            </Button>
            <Button
              variant={selectedType === "Quotes" ? "contained" : "outlined"}
              onClick={() => handleFilterChange("Quotes")}
            >
              Quotes
            </Button>
            <Button
              variant={
                selectedType === "Sales Closed" ? "contained" : "outlined"
              }
              onClick={() => handleFilterChange("Sales Closed")}
            >
              Sales Closed
            </Button>
          </ButtonGroup>
        }
        title="Sales"
      />
      <CardContent>
        <BarChart
          dataset={filteredData}
          xAxis={[{ scaleType: "band", dataKey: "month" }]}
          series={[
            selectedType === "All"
              ? { dataKey: "Client Visits", label: "Client Visits", valueFormatter }
              : { dataKey: selectedType, label: selectedType, valueFormatter },
            selectedType === "All" && {
              dataKey: "New Prospects",
              label: "New Prospects",
              valueFormatter
            },
            selectedType === "All" && { dataKey: "Quotes", label: "Quotes", valueFormatter },
            selectedType === "All" && {
              dataKey: "Sales Closed",
              label: "Sales Closed",
              valueFormatter
            },
          ].filter(Boolean)}
          {...chartSetting}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              padding: -5,
            },
          }}
        />
      </CardContent>
    </Card>
  );
}
