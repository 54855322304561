import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function UserAccount({ open, onClose, currentUser }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Account Details
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Name:</strong> {currentUser.Name}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Email:</strong> {currentUser.Email}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Department:</strong> {currentUser.Department}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Position:</strong> {currentUser.Position}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Role:</strong> {currentUser.Role}
          </Typography>
          {currentUser.Status && (
            <Typography variant="body1">
              <strong>Status:</strong> Active
            </Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
